import { combineReducers } from 'redux';
import auth from './auth';
import general from './general';
import firebase from './firebase';
import staticData from './staticData';

export default combineReducers({
  auth,
  general,
  firebase,
  staticData,
});
