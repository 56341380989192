import React from 'react';
import AboutPanel from '@pages/home/AboutPanel';
import { Close } from '@material-ui/icons';

export default (p) => {
  return (
    <div className="w-100 h-100 position-relative">
      <div
        className="position-absolute ml-3"
        style={{ bottom: '-80vh', right: '4em', zIndex: 999 }}
      >
        <div
          className="p-2 position-absolute"
          style={{ top: '10px', left: '10px' }}
        >
          <Close
            className="cursor-pointer bg-light"
            style={{ color: '#161944' }}
            onClick={() => p.setIsShowAbout(false)}
          />
        </div>
        <AboutPanel />
      </div>
    </div>
  );
};
