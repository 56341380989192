import { Cookies } from 'react-cookie';

/**
 * Returns the logged in user
 */
export const getUserFromCookie = () => {
  const cookies = new Cookies();
  const user = cookies.get('user');
  if (!user) return null;

  return typeof user === 'object' ? user : JSON.parse(user);
};
export const getTokenCookie = () => {
  const cookies = new Cookies();
  return cookies.get('token');
};

export const isEmailValid = (email) => {
  const emailRegex = /[\w-]+@([\w-]+\.)+[\w-]+/;
  return emailRegex.test(email);
};
