import React from 'react';
import { useTranslation } from 'react-i18next';
import GreenTechLogo from '@components/GreenTechLogo';

export default () => {
  const { t } = useTranslation();
  return (
    <div
      className="p-4"
      style={{ backgroundColor: '#161944', maxWidth: '35em' }}
    >
      <div className="text-white text-right">{t('about_panel.title')}</div>
      <hr style={{ border: '.2px solid white' }} />
      <div
        className="text-white"
        style={{ textAlign: 'justify', textJustify: 'inter-word', textAlignLast: 'right' }}
      >{t('about_panel.body')}
      </div>
      <div className="row mt-4">
        <div className="col-4">
          <GreenTechLogo style={{ height: '80%', width: 'auto' }} />
        </div>
        <div className="col-8 text-white text-right space-children-vertical">
          <div>{t('about_panel.contact')}</div>
          <div>03 - 8921 0800</div>
          <div>lowcarboncities@greentechmalaysia.my</div>
        </div>
      </div>
    </div>
  );
};
