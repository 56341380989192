import { Cookies } from 'react-cookie';
import { getUserFromCookie, getTokenCookie } from '@helpers/authUtils';
import Api from '@helpers/api';
import { endpoints } from '@configs/endpoints';
import { dispatch } from './store';
import { apiGetProfile } from './action/auth';

export const SET_TOKEN = 'SET_TOKEN';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAILED = 'FORGET_PASSWORD_FAILED';

const error_login = (r) => {
  let error = r;
  if (r.status === 403) {
    error = 'Login failed. Please try again later.';
  }
  dispatch({ type: LOGIN_USER_FAILED, payload: error });
};
export const loginUser = ({ email, password, redirect_url }) => {
  dispatch({ type: LOGIN_USER });
  Api({
    endpoint: endpoints.getToken(),
    data: { email, password },
    onSuccess: (response) => {
      const token = response;

      apiGetProfile({
        token: token.token,
        onSuccess: (r) => {
          const user = r.data;
          dispatch({ type: LOGIN_USER_SUCCESS, payload: user });
          dispatch({ type: SET_TOKEN, payload: token });
          (new Cookies()).set('token', JSON.stringify({ token }), { path: '/' });

          window.location = redirect_url || '/';
        },
        onFail: error_login,
      });
    },
    onFail: error_login,
  });
};

export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});

export const loginUserFailed = (error) => ({
  type: LOGIN_USER_FAILED,
  payload: error,
});

export const registerUser = (fullname, email, password) => ({
  type: REGISTER_USER,
  payload: { fullname, email, password },
});

export const registerUserSuccess = (user) => ({
  type: REGISTER_USER_SUCCESS,
  payload: user,
});

export const registerUserFailed = (error) => ({
  type: REGISTER_USER_FAILED,
  payload: error,
});

export const logoutUser = (history) => {
  dispatch({ type: LOGOUT_USER });
  (new Cookies()).remove('user');
  (new Cookies()).remove('token');
  if (!history) {
    window.location = '/login';
  } else {
    history.push('/login');
  }
};

export const forgetPassword = (email) => ({
  type: FORGET_PASSWORD,
  payload: { email },
});

export const forgetPasswordSuccess = (passwordResetStatus) => ({
  type: FORGET_PASSWORD_SUCCESS,
  payload: passwordResetStatus,
});

export const forgetPasswordFailed = (error) => ({
  type: FORGET_PASSWORD_FAILED,
  payload: error,
});

const INIT_STATE = {
  profile: getUserFromCookie(),
  token: getTokenCookie(),
  is_loading: false,
};

// eslint-disable-next-line complexity
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, is_loading: true, error: null };
    case LOGIN_USER_SUCCESS:
      return {
        ...state, is_loading: false, error: null,
      };
    case LOGIN_USER_FAILED:
      return { ...state, error: action.payload, is_loading: false };
    case REGISTER_USER:
      return { ...state, is_loading: true };
    case 'SET_PROFILE':
      return { ...state, profile: action.payload };
    case REGISTER_USER_SUCCESS:
      return {
        ...state, profile: action.payload, is_loading: false, error: null,
      };
    case REGISTER_USER_FAILED:
      return { ...state, error: action.payload, is_loading: false };
    case LOGOUT_USER:
      return { ...state, profile: null };
    case SET_TOKEN:
      return { ...state, token: action.payload };
    case FORGET_PASSWORD:
      return { ...state, is_loading: true };
    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state, passwordResetStatus: action.payload, is_loading: false, error: null,
      };
    case FORGET_PASSWORD_FAILED:
      return { ...state, error: action.payload, is_loading: false };
    default: return { ...state };
  }
};
