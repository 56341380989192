import { createSelector } from 'reselect';

export const isAuthenticated = createSelector(
  [s => s],
  s => !!s.auth?.token?.token,
);

export const isAdmin = createSelector(
  [s => s],
  s => s.auth?.profile?.Role?.id === 1,
);

export const isManager = createSelector(
  [s => s],
  s => s.auth?.profile?.Role?.id === 2,
);

export const isUser = createSelector(
  [s => s],
  s => s.auth?.profile?.Role?.id === 3,
);

export const isAuditor = createSelector(
  [s => s],
  s => s.auth?.profile?.Role?.id === 4,
);

export const isCoordinator = createSelector(
  [s => s],
  s => s.auth?.profile?.Role?.id === 5,
);

export const isNational = createSelector(
  [s => s],
  s => s.auth?.profile?.Role?.id === 6,
);

export const isViewer = createSelector(
  [s => s],
  s => s.auth?.profile?.Role?.id === 7,
);
