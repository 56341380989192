import React from 'react';
import { useTranslation } from 'react-i18next';
import { version } from '../../package.json';

export default () => {
  const { t } = useTranslation();
  return (
    <div
      className="sm-hidden-md-flex background-gradient-primary text-white justify-content-center p-2"
      style={{ fontSize: '.8em' }}
    >
      <div className="nowrap">{t('footer.copyright')}</div>
      <div className="ml-5 nowrap">{`${t('common.version').toUpperCase()}: ${version}`}</div>
    </div>
  );
};
