import React from 'react';
import { initialsFromUser, stringToColor } from '@helpers';

export default ({ size, user }) => {
  const initials = initialsFromUser(user);
  return (
    <div
      className="rounded-circle all-center text-white font-weight-bold overflow-hidden"
      style={{
        height: size, width: size, backgroundColor: stringToColor(initials),
      }}
    >
      {!!user.image ? <img className="w-100 h-100" src={user.image} alt="User profile" />
        : <span className="text-shadow">{initials}</span>}
    </div>
  );
};
