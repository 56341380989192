import { useEffect } from 'react';
// import { db } from '@configs/firebaseConfig';
// import { dispatch } from '@redux/store';

export default () => {
  useEffect(() => {
    // db.ref('notifications/assets/').on('value', (snapshot) => {
    //   const data = snapshot.val();
    //   if (!data || Object.keys(data).length === 0) {
    //     console.log('no response');
    //   }
    //   dispatch({ type: 'firebase/SET_FIREBASE_ASSETS', data });
    // });
    // return () => db.ref('notifications/assets/').off();
  }, []);
  return null;
};
