import Api from '@helpers/api';
import { endpoints } from '@configs/endpoints';
import { dispatch } from './store';

export const GET_STATIC_DATA = 'GET_STATIC_DATA';
export const GET_STATIC_DATA_SUCCESS = 'GET_STATIC_DATA_SUCCESS';
export const GET_STATIC_DATA_FAILED = 'GET_STATIC_DATA_FAILED';

export const getStaticData = () => {
  dispatch({ type: GET_STATIC_DATA });
  Api({
    endpoint: endpoints.getStaticData(),
    onSuccess: ({ data }) => {
      dispatch({ type: GET_STATIC_DATA_SUCCESS, payload: data });
    },
    onFail: (error) => {
      dispatch({ type: GET_STATIC_DATA_FAILED, payload: error });
    },
  });
};

const INIT_STATE = {
  data: {},
  is_loading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STATIC_DATA:
      return { ...state, is_loading: true, error: null };
    case GET_STATIC_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        is_loading: false,
      };
    case GET_STATIC_DATA_FAILED:
      return { ...state, error: action.payload, is_loading: false };
    default:
      return { ...state };
  }
};
