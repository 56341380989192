export const URL = process.env.REACT_APP_ENDPOINT_URL;

export const endpoints = {
  getStaticData: () => ['GET', `${URL}staticdata`],
  getToken: () => ['POST', `${URL}login`],
  register: () => ['POST', `${URL}signup`],
  subscribe: () => ['POST', `${URL}subscribe`],
  getProfile: () => ['GET', `${URL}api/profile`],
  updateProfile: (id) => ['POST', `${URL}api/user/${id}`],
  requestTokenToResetPassword: () => ['POST', `${URL}forgot-password`],
  verifyResetPasswordToken: () => ['POST', `${URL}verify-reset-password-token`],
  resetPassword: () => ['POST', `${URL}reset-password`],
  getActivityLog: () => ['GET', `${URL}api/activitylog`],
  verifyToken: () => ['POST', `${URL}verify-email-token`],

  getUsers: () => ['GET', `${URL}api/users`],
  deleteUser: (id) => ['DELETE', `${URL}api/user/${id}`],
  verifyUser: () => ['POST', `${URL}api/verify-user`],
  assignUser: () => ['POST', `${URL}api/assign-user`],

  getSubscribers: () => ['GET', `${URL}api/subscribers`],
  deleteSubscriber: (id) => ['DELETE', `${URL}api/subscriber/${id}`],
  sendLinkToSubscriber: () => ['POST', `${URL}api/sent-reg-link-subscriber`],
  verifySubscriber: () => ['POST', `${URL}api/verify-subscriber`],

  changeAuditorZone: (id) => ['POST', `${URL}api/changeauditor-zone/${id}`],
  changeAuditorPartner: (id) => ['POST', `${URL}api/changeauditor-partner/${id}`],

  getSolutionPresets: () => ['GET', `${URL}api/solution-presets`],
  updateSolutionPreset: (id) => ['POST', `${URL}api/solution-preset/${id}`],
  deleteSolutionPreset: (id) => ['DELETE', `${URL}api/solution-preset/${id}`],
  createSolutionPreset: () => ['POST', `${URL}api/solution-preset`],
  getSolutionPresetNames: () => ['GET', `${URL}api/solution-preset-names`],
  getSolutionPresetByType: (id, preset_name) => ['GET', `${URL}api/solution-presets_bytype/${id}/${preset_name}`],

  getSolutionPlans: (id) => ['GET', `${URL}api/solution-plans${id ? `/${id}` : ''}`],
  updateSolutionPlan: (id) => ['POST', `${URL}api/solution-plan/${id}`],
  deleteSolutionPlan: (id) => ['DELETE', `${URL}api/solution-plan/${id}`],
  createSolutionPlan: () => ['POST', `${URL}api/solution-plan`],

  getZone: (id) => ['GET', `${URL}api/zone/${id}`],
  getZones: () => ['GET', `${URL}api/zone`],
  getZoneCo2: (id) => [
    'GET',
    `${URL}api/zone/${id}/show_cumulative_carbon`,
  ],
  addZone: () => ['POST', `${URL}api/zone`],
  updateZone: (id) => ['POST', `${URL}api/zone/${id}`],
  verifyZone: () => ['POST', `${URL}api/verify-zone`],
  deleteZone: (id) => ['DELETE', `${URL}api/zone/${id}`],

  getPartners: () => ['GET', `${URL}api/partner`],
  getPartnerCo2: (id) => [
    'GET',
    `${URL}api/partner/${id}/show_cumulative_carbon`,
  ],
  getPartner: (id) => ['GET', `${URL}api/partner/${id}`],
  verifyPartner: () => ['POST', `${URL}api/verify-partner`],
  deletePartner: (id) => ['DELETE', `${URL}api/partner/${id}`],
  addPartner: () => ['POST', `${URL}api/partner`],
  updatePartner: (id) => ['POST', `${URL}api/partner/${id}`],

  getEmissionParameter: () => ['GET', `${URL}emissionparameter`],
  updateEmissionParameter: () => ['POST', `${URL}api/emissionparameter`],
  getOverviewCumulative: () => ['GET', `${URL}api/overview-cumulative`],

  getMapSidePanelInfo: () => ['GET', `${URL}map-sidepanel-info`],
  getEmissionCumulative: () => ['GET', `${URL}emission-cumulative`],
  getCO2Cumulative: () => ['GET', `${URL}co2-cumulative`],

  getNationalReportings: () => ['GET', `${URL}api/nationalreportings`],
  updateNationalReportings: () => ['POST', `${URL}api/nationalreportings`],
  getNationalGPS: () => ['GET', `${URL}api/nationalgps`],
  updateNationalGPS: () => ['POST', `${URL}api/nationalgps`],
  getNationalNEB: () => ['GET', `${URL}api/nationalneb`],
  updateNationalNEB: () => ['POST', `${URL}api/nationalneb`],
  getNationalRE: () => ['GET', `${URL}api/nationalre`],
  updateNationalRE: () => ['POST', `${URL}api/nationalre`],
  getNationalEMGS: () => ['GET', `${URL}api/nationalemgs`],
  updateNationalEMGS: (id) => ['POST', `${URL}api/nationalemgs/${id}`],
  getNationalGTFS: () => ['GET', `${URL}api/nationalgtfs`],
  updateNationalGTFS: (id) => ['POST', `${URL}api/nationalgtfs/${id}`],
  getNationalMobility: () => ['GET', `${URL}api/nationalmobility`],
  updateNationalMobility: (id) => ['POST', `${URL}api/nationalmobility/${id}`],
  getNationalWaste: () => ['GET', `${URL}api/nationalwaste`],
  updateNationalWaste: (id) => ['POST', `${URL}api/nationalwaste/${id}`],

  getEmissions: () => ['GET', `${URL}api/emissions`],
  updateEmissions: () => ['POST', `${URL}api/emissions`],
  approveEmissions: () => ['POST', `${URL}api/emissions_verify_all`],
};
