import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const favicon = require('@assets/icons/lc4-logo.jpeg');

export default () => {
  const { t } = useTranslation();
  return (
    <Helmet>
      <meta content={t('app_name')} name="description" />
      <title>{t('app_name')}</title>
      <link id="favicon" rel="icon" href={favicon} type="image/x-icon" />
    </Helmet>
  );
};
