import React from 'react';
import ReactDOM from 'react-dom';
// import * as Sentry from '@sentry/browser';
import { Provider } from 'react-redux';
import { configureStore } from '@redux/store';
import { I18nextProvider } from 'react-i18next';
import i18n from '@locale';
import * as serviceWorker from './serviceWorker';
import App from './App';

function removeConsole() { }

if (process.env.NODE_ENV !== 'development') {
  console.log = removeConsole;
  console.warn = removeConsole;
  console.error = removeConsole;

  // Sentry.init({
  //   dsn: 'https://b6fa9f9281804958aed8afc3d92938a8@o406540.ingest.sentry.io/5278247',
  // });
}

ReactDOM.render(
  <Provider store={configureStore()}>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
