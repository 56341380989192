import { Tab } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export default withStyles(() => ({
  root: {
    fontSize: '.7em',
    padding: 0,
    minHeight: '3em',
  },
}))(Tab);
