import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loadable from 'react-loadable';
import Loading from '@components/Loading';
import { isAuthenticated } from '@redux/selectors/profile';
import Helmet from '@components/helmet';
import Firebase from '@components/firebase';
import Footer from '@components/Footer';
import AuthHeader from '@components/AuthHeader';
import { getStaticData } from '@redux/staticData';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { routes } from './routes';
import ErrorBoundary from './errorBoundary';

// Themes
import '@assets/scss/DefaultTheme.scss';

// MDBootstrap
// eslint-disable-next-line import/no-extraneous-dependencies
import '@fortawesome/fontawesome-free/css/all.min.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';

// Lazy loading and code splitting -
// Derieved idea from https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52

// All layouts/containers
const NonAuthLayout = Loadable({
  loader: () => import('@components/Layout'),
  render(loaded, props) {
    return <loaded.default {...props} />;
  },
  loading: Loading,
});

const AuthLayout = Loadable({
  loader: () => import('@components/Layout'),
  render(loaded, props) {
    return (
      <>
        <loaded.default {...props} />
        <Firebase />
      </>
    );
  },
  loading: Loading,
});

export default () => {
  const isAuth = useSelector(isAuthenticated);
  const Layout = isAuth ? AuthLayout : NonAuthLayout;

  useEffect(() => {
    getStaticData();
  }, []);

  return (
    <BrowserRouter>
      <Helmet />
      <ToastContainer autoClose={5000} />
      <Switch>
        {routes.map((route) => (
          <route.route
            key={route.path || `${Math.random()}`}
            {...route}
            component={(p) => (
              <Suspense fallback={Loading}>
                <ErrorBoundary>
                  <Layout {...p}>
                    <div className="fullscreen d-flex flex-column">
                      {(isAuth && !route.hideAuthHeader) && <AuthHeader />}
                      <route.component {...p} />
                      {!route.hideFooter && <Footer />}
                    </div>
                  </Layout>
                </ErrorBoundary>
              </Suspense>
            )}
          />
        ))}
      </Switch>
    </BrowserRouter>
  );
};
