import React from 'react';

export default class ErrorBoundary extends React.Component {
  state = { hasError: false, errorInfo: '', error: '' };

  static getDerivedStateFromError(error) {
    console.log('error', error);
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ errorInfo: JSON.stringify(errorInfo) });
    if (!!error) {
      this.setState({ error: JSON.stringify(error) });
    }
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <h1>Something went wrong.</h1>
          {!!this.state.error && (
            <>
              <div><b>Error</b></div>
              <div>{this.state.error}</div>
            </>
          )}
          <div><b>Error Info</b></div>
          <div>{this.state.errorInfo}</div>
        </>
      );
    }

    return this.props.children;
  }
}
