/* eslint-disable max-lines */
import React from 'react';
import { Redirect, Route, useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isAuthenticated, isAdmin, isAuditor } from '@redux/selectors/profile';

const Dashboard = React.lazy(() => import('@pages/dashboard/'));
const Home = React.lazy(() => import('@pages/home/'));
const NotFound = React.lazy(() => import('@pages/notfound'));
const Analytics = React.lazy(() => import('@pages/analytics'));
const Denied = React.lazy(() => import('@pages/denied'));
const Landing = React.lazy(() => import('@pages/auth/LandingPage'));
const Logout = React.lazy(() => import('@pages/auth/Logout'));
const Project = React.lazy(() => import('@pages/project'));
const Profile = React.lazy(() => import('@pages/profile'));
const ManageUser = React.lazy(() => import('@pages/manageUser'));
const EmissionParameter = React.lazy(() => import('@pages/emissionParameter'));
const UserManagement = React.lazy(() => import('@pages/userManagement'));
const SubscriberManagement = React.lazy(() => import('@pages/subscriberManagement'));
const ProjectManagement = React.lazy(() => import('@pages/projectManagement'));
const ProjectAuditor = React.lazy(() => import('@pages/projectAuditor'));
const ProjectCoordinator = React.lazy(() => import('@pages/projectCoordinator'));
const BuildingManagement = React.lazy(() => import('@pages/buildingManagement'));
const DataManagement = React.lazy(() => import('@pages/dataManagement'));
const Planning = React.lazy(() => import('@pages/planning'));
const SolutionPreset = React.lazy(() => import('@pages/solutionPreset'));
const NationalDataEntry = React.lazy(() => import('@pages/nationalDataEntry'));
const ZoneDataEntry = React.lazy(() => import('@pages/zoneDataEntry'));
const BuildingDataEntry = React.lazy(() => import('@pages/buildingDataEntry'));
const UserLog = React.lazy(() => import('@pages/userLog'));
const Zone = React.lazy(() => import('@pages/zone'));
const Partner = React.lazy(() => import('@pages/partner'));
const VerifyUser = React.lazy(() => import('@pages/auth/VerifyUser'));

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const isAuth = useSelector(isAuthenticated);
  const history = useHistory();
  const location = useLocation();
  const is_admin = useSelector(isAdmin);
  const is_auditor = useSelector(isAuditor);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuth) {
          return (
            <Redirect to={{ pathname: '/login', state: { from: location } }} />
          );
        }

        if (!!roles && roles.indexOf('admin') !== -1 && !is_admin) {
          history.push('/denied');
          return null;
        }

        if (!!roles && roles.indexOf('auditor') !== -1 && !is_auditor) {
          history.push('/denied');
          return null;
        }

        return <Component {...props} />;
      }}
    />
  );
};

const Root = () => {
  const isAuth = useSelector(isAuthenticated);
  return <Redirect to={isAuth ? '/home' : '/welcome'} />;
};

const routes = [
  {
    path: '/login',
    exact: true,
    component: Landing,
    hideFooter: true,
    route: Route,
  },
  {
    path: '/welcome',
    exact: true,
    component: Landing,
    hideFooter: true,
    route: Route,
  },
  {
    path: '/register',
    exact: true,
    component: Landing,
    hideFooter: true,
    hideAuthHeader: true,
    route: Route,
  },
  {
    path: '/reset-password',
    exact: true,
    component: Landing,
    hideFooter: true,
    route: Route,
  },
  {
    path: '/userverify',
    exact: true,
    component: VerifyUser,
    hideFooter: true,
    hideAuthHeader: true,
    route: Route,
  },
  {
    path: '/logout',
    exact: true,
    component: Logout,
    route: Route,
  },
  {
    path: '/home',
    exact: true,
    component: Home,
    route: Route,
  },
  {
    path: '/subscribe',
    exact: true,
    component: Landing,
    route: Route,
  },
  {
    path: '/profile',
    exact: true,
    component: Profile,
    route: PrivateRoute,
  },
  {
    path: '/home/dashboard',
    exact: true,
    component: Dashboard,
    route: PrivateRoute,
  },
  {
    path: '/user-management',
    exact: true,
    component: UserManagement,
    route: PrivateRoute,
    roles: ['admin'],
  },
  {
    path: '/subscriber-management',
    exact: true,
    component: SubscriberManagement,
    route: PrivateRoute,
    roles: ['admin'],
  },
  {
    path: '/manage-user/:user_id',
    exact: true,
    component: ManageUser,
    route: PrivateRoute,
    roles: ['admin'],
  },
  {
    path: '/user-logs/:user_id?',
    exact: true,
    component: UserLog,
    route: PrivateRoute,
    roles: ['admin'],
  },
  {
    path: '/data-management',
    exact: true,
    component: DataManagement,
    route: PrivateRoute,
    roles: ['auditor'],
  },
  {
    path: '/project-management',
    exact: true,
    component: ProjectManagement,
    route: PrivateRoute,
    roles: ['admin'],
  },
  {
    path: '/project-auditor',
    exact: true,
    component: ProjectAuditor,
    route: PrivateRoute,
    roles: ['auditor'],
  },
  {
    path: '/project-coordinator',
    exact: true,
    component: ProjectCoordinator,
    route: PrivateRoute,
    roles: ['coordinator'],
  },
  {
    path: '/building-management',
    exact: true,
    component: BuildingManagement,
    route: PrivateRoute,
    roles: ['admin'],
  },
  {
    path: '/solution-preset',
    exact: true,
    component: SolutionPreset,
    route: PrivateRoute,
    roles: ['admin'],
  },
  {
    path: '/solution-preset/:preset_id',
    exact: true,
    component: SolutionPreset,
    route: PrivateRoute,
    roles: ['admin'],
  },
  {
    path: '/analytics',
    exact: true,
    component: Analytics,
    route: PrivateRoute,
  },
  {
    path: '/planning',
    exact: true,
    component: Planning,
    route: PrivateRoute,
  },
  {
    path: '/project',
    exact: true,
    component: Project,
    route: PrivateRoute,
  },
  {
    path: '/setting',
    exact: true,
    component: EmissionParameter,
    route: PrivateRoute,
  },
  {
    path: '/national-data-entry',
    exact: true,
    component: NationalDataEntry,
    route: PrivateRoute,
  },
  {
    path: '/zone-data-entry/:id',
    exact: true,
    component: ZoneDataEntry,
    route: PrivateRoute,
  },
  {
    path: '/building-data-entry/:id',
    exact: true,
    component: BuildingDataEntry,
    route: PrivateRoute,
  },
  {
    path: '/add-zone',
    exact: true,
    component: Zone,
    route: PrivateRoute,
  },
  {
    path: '/edit-zone/:id',
    exact: true,
    component: Zone,
    route: PrivateRoute,
  },
  {
    path: '/add-partner',
    exact: true,
    component: Partner,
    route: PrivateRoute,
  },
  {
    path: '/edit-partner/:id',
    exact: true,
    component: Partner,
    route: PrivateRoute,
  },
  {
    path: '/denied',
    exact: true,
    component: Denied,
    route: Route,
  },
  {
    path: '/',
    exact: true,
    component: Root,
    route: Route,
  },
  {
    component: NotFound,
    route: Route,
  },
];

export { routes };
