import moment from 'moment';
import HRNumbers from 'human-readable-numbers';

export const getTenant = () => {
  const details = {
    type: 'main',
    title: 'BandarOS',
  };
  return details;
};

export const displayCurrency = (num) => `RM ${HRNumbers.toHumanString(num)}`;

export const toUTC = (time) => moment(time).subtract(8, 'hours');

export const fromUTC = (time) => moment(time).add(8, 'hours');

export const initialsFromUser = (user) => {
  const str = user.name || user.email;
  if (!str) return '';
  return str
    .replace(/[^0-9a-z ]/gi, '')
    .split(' ')
    ?.map((i) => i.charAt(0))
    .join('')
    .toUpperCase()
    .slice(0, 2);
};

export const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

export const stringToColor = (str) => {
  const hashCode = (s) => {
    let hash = 0;
    for (let i = 0; i < `${s}`.length; i++) {
      hash = `${s}`.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  };
  const intToRGB = (i) => {
    const c = (i & 0x00ffffff).toString(16).toUpperCase();

    return ''.substring(0, 6 - c.length) + c;
  };

  return `#${intToRGB(hashCode(str))}`;
};

export const snakeCaseToTitleCase = (str) => {
  if (!str) return '';

  const sentence = str.replace(/_/gi, ' ').toLowerCase().split(' ');
  for (let i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
  }

  return sentence.join(' ');
};

export const arrayRemove = (arr, value) => arr.filter((ele) => ele !== value);

export const groupEmissionCumulativeIntoArrayOfTypes = (data) => {
  const emission_cumulative = {};
  Object.keys(data).forEach((key) => {
    const groupByState = data[key];
    Object.keys(groupByState).forEach((iterate_year) => {
      groupByState[iterate_year].forEach((emission_data) => {
        if (!emission_cumulative[emission_data.EmissionDataKeyId]) {
          emission_cumulative[emission_data.EmissionDataKeyId] = emission_data;
        } else {
          emission_cumulative[emission_data.EmissionDataKeyId].value += emission_data.value;
        }
      });
    });
  });

  let groupedDataByType = Object.keys(emission_cumulative)?.map((key) => emission_cumulative[key]);
  // put Others at the last index of array
  groupedDataByType = groupedDataByType.sort((a) => (a.label === 'Others' ? 1 : -1));
  return groupedDataByType;
};

export const groupEmissionCumulativeIntoStatesAndTypes = (data) => {
  const emission_by_state_cumulative = {};
  Object.keys(data).forEach((key) => {
    const emission_cumulative = {};
    const groupByState = data[key];
    Object.keys(groupByState).forEach((iterate_year) => {
      groupByState[iterate_year].forEach((emission_data) => {
        if (!emission_cumulative[emission_data.EmissionDataKeyId]) {
          emission_cumulative[emission_data.EmissionDataKeyId] = emission_data;
        } else {
          emission_cumulative[emission_data.EmissionDataKeyId].value += emission_data.value;
        }
      });
    });
    emission_by_state_cumulative[key] = emission_cumulative;
  });

  return emission_by_state_cumulative;
};

export const groupEmissionCumulativeIntoArrayOfYears = (data) => {
  const results = {};
  Object.keys(data).forEach((key) => {
    Object.keys(data[key]).forEach((year) => {
      if (!results[year]) {
        results[year] = data[key][year];
      }
    });
  });
  return results;
};

export const roundToTwoDecimals = (num) => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};
