// only put action here if got problem with import cycle
import { Cookies } from 'react-cookie';
import { dispatch } from '@redux/store';
import Api from '@helpers/api';
import { endpoints } from '@configs/endpoints';

export const apiGetProfile = (args) => {
  const onSuccess = args?.onSuccess;
  const onFail = args?.onFail;
  const token = args?.token;

  const params = {
    endpoint: endpoints.getProfile(),
    onSuccess: (response) => {
      const user = response.data;
      dispatch({ type: 'SET_PROFILE', payload: user });
      (new Cookies()).set('user', JSON.stringify({ ...user }), { path: '/' });
      if (!!onSuccess) {
        onSuccess(response);
      }
    },
    onFail: e => {
      if (!!onFail) {
        onFail(e);
      }
    },
  };
  if (token) {
    params.token = token;
  }

  Api(params);
};
