/* eslint-disable complexity */
import React from 'react';
import { MDBNavbar, MDBNavbarBrand } from 'mdbreact';
import { Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import { useSelector } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { isAdmin, isManager, isUser, isAuditor, isCoordinator, isViewer } from '@redux/selectors/profile';
import UserImage from '@components/UserImage';
import { a11yProps } from '@helpers';
import Logo from '@components/Logo';
import Tab from './Tab';
import Tabs from './Tabs';
import AboutPanel from './AboutPanelFloat';
import './styles.scss';

export default () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { profile } = useSelector((s) => s.auth);
  const is_admin = useSelector(isAdmin);
  const is_manager = useSelector(isManager);
  const is_user = useSelector(isUser);
  const is_auditor = useSelector(isAuditor);
  const is_coordinator = useSelector(isCoordinator);
  const is_viewer = useSelector(isViewer);

  const menus = [];
  if (is_admin || is_user || is_manager) {
    menus.push({ label: t('header.home'), path: '/home' });
    menus.push({ label: t('header.project'), path: '/project' });
    menus.push({ label: t('header.analytics'), path: '/analytics' });
    menus.push({ label: t('header.planning'), path: '/planning' });
  }
  if (is_auditor) {
    menus.push({ label: t('header.home'), path: '/home' });
    menus.push({ label: t('header.project'), path: '/project-auditor' });
  }
  if (is_coordinator) {
    menus.push({ label: t('header.home'), path: '/home' });
    menus.push({ label: t('header.project'), path: '/project-coordinator' });
  }
  if (is_viewer) {
    menus.push({ label: t('header.home'), path: '/home' });
    menus.push({ label: t('header.project'), path: '/project' });
    menus.push({ label: t('header.analytics'), path: '/analytics' });
  }

  const sub_menus = [];
  sub_menus.push({ path: '/profile', label: t('profile_page.title') });

  if (is_admin) {
    sub_menus.push({
      path: '/setting',
      label: t('emission_parameter_page.page_title'),
    });
    sub_menus.push({
      path: '/solution-preset',
      label: t('solution_preset_page.title'),
    });
    sub_menus.push({
      path: '/user-management',
      label: t('user_management_page.title'),
    });
    sub_menus.push({
      path: '/project-management',
      label: t('project_management_page.title'),
    });
    sub_menus.push({
      path: '/building-management',
      label: t('building_management_page.title'),
    });
  }

  if (is_auditor) {
    sub_menus.push({
      path: '/data-management',
      label: t('data_management_page.title'),
    });
  }
  if (is_admin) {
    sub_menus.push({ path: '/user-logs', label: t('activity_log_page.title') });
  }
  sub_menus.push({ path: '/logout', label: t('common.logout') });

  const handleChange = (e, value) => history.push(menus[value].path);

  let menu_index = -1;

  menus.forEach((menu, index) => {
    if (location.pathname.startsWith(menu.path)) {
      menu_index = index;
    }
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isShowAbout, setIsShowAbout] = React.useState(false);
  const handleClickMenu = (event) => setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);
  const onClickMenuItem = (path) => {
    handleCloseMenu();
    history.push(path);
  };
  const onClickShowAbout = () => { setIsShowAbout(!isShowAbout); };
  const tabsActions = React.useRef();

  // this use effect is workaround to solve bug as mentioned in link below
  // https://github.com/mui-org/material-ui/issues/9337
  React.useEffect(() => {
    if (menu_index >= 0) {
      setTimeout(() => {
        if (tabsActions.current) {
          tabsActions.current.updateIndicator();
        }
      }, 400);
    }
  }, [menu_index]);

  if (!profile) return null;

  return (
    <MDBNavbar className="background-gradient-primary" style={{ zIndex: 1000 }}>
      <div className="d-flex justify-content-between align-items-center w-100">
        <MDBNavbarBrand
          href="/"
          className="py-0 font-weight-bold d-flex align-items-center"
          style={{ width: '33.33vw' }}
        >
          <Logo style={{ width: '2em', height: '2em' }} />
        </MDBNavbarBrand>
        <div className="sm-hidden-md-flex">
          <Tabs
            action={tabsActions}
            // indicatorColor="white"
            value={menu_index}
            onChange={handleChange}
          >
            {menus.map((m, idx) => (
              <Tab label={m.label} key={m.label} {...a11yProps(idx)} />
            ))}
          </Tabs>
        </div>
        <div
          className="d-flex justify-content-end"
          style={{ width: is_auditor ? '100%' : '30vw' }}
        >
          <div
            onBlur={handleCloseMenu}
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClickMenu}
            className="d-flex cursor-pointer align-items-center"
          >
            <UserImage size="1.5em" user={profile} />
            <ExpandMoreIcon />
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
              <MenuItem disabled className="d-flex justify-content-between">
                <div className="mr-2">{profile.name || profile.email}</div>
                <UserImage size="2.5em" user={profile} />
              </MenuItem>
              <div className="sm-initial-md-hidden">
                <Divider />
                {menus.map((m) => (
                  <MenuItem
                    key={m.label + m.path}
                    onClick={() => onClickMenuItem(m.path)}
                    children={m.label}
                  />
                ))}
              </div>
              <Divider />
              <MenuItem onClick={() => onClickShowAbout()}>
                {t('common.about')}
              </MenuItem>
              {sub_menus.map((m) => (
                <MenuItem onClick={() => onClickMenuItem(m.path)}>
                  {m.label}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </div>
      </div>
      {isShowAbout && (<AboutPanel setIsShowAbout={setIsShowAbout} />)}
    </MDBNavbar>
  );
};
